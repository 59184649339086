import { CigarLogModel } from '@shared/models/cigar-log.model';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import _pick from 'lodash-es/pick';
import queryString from 'query-string';

import {
  ICigar,
  ICigarLog,
  ICigarLogFilters,
  ICigarLogListType,
} from '@models';
import { DeviceService } from '@shared/services/device.service';
import { UserType } from '@shared/models/user.model';
import { environment } from '@environments/environment';

export const DATE_SORT_FIELD = 'Date';
export const NAME_SORT_FIELD = 'Product.Name';
export const RATING_SORT_FIELD = 'Product.RatingSummary.AverageRating';
export const MY_RATING_SORT_FIELD = 'Product.MyRating.Rating';

@Injectable({
  providedIn: 'root',
})
export class CigarLogService {
  constructor(
    protected http: HttpClient,
    private deviceService: DeviceService
  ) {}

  getOne(logId: number) {
    return this.http.get<ICigarLog>(`cigarlogs/${logId}`);
  }

  /**
   * @deprecated CigarLogModel is deprecated
   * use getOne instead when possible
   */
  getOneLegacy(logId: number) {
    return this.http
      .get<ICigarLog>(`cigarlogs/${logId}`)
      .pipe(map((cigarLog) => new CigarLogModel(cigarLog)));
  }

  getUserList(
    userId: string,
    userType: UserType,
    list: string,
    skip: number,
    take: number = environment.pageLimit
  ) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', take.toString());
    return this.http.get<ICigarLog[]>(
      `social/${userType}/${userId}/cigarlogs/list/${list}`,
      {
        params,
      }
    );
  }

  getConnectedUserList(
    listName: ICigarLogListType,
    filters: ICigarLogFilters,
    skip?: number,
    take: number = environment.pageLimit
  ) {
    return this.http.get<ICigarLog[]>(
      `cigarlogs/listing/${listName}?${queryString.stringify({
        searchString: filters.search || undefined,
        sortBy: filters.sort || undefined,
        SortDescending: filters.order ? filters.order === 'desc' : undefined,
        take,
        skip,
      })}`,
      {
        observe: 'response',
      }
    );
  }

  createLogFromProductId(
    ProductId: number,
    List: ICigarLogListType,
    {
      UserImageUrl,
      Location,
      RecognitionId,
    }: {
      UserImageUrl?: string;
      Location?: string;
      RecognitionId?: number;
    }
  ) {
    return this.http.post<ICigarLog>('cigarlogs', {
      UUID: this.deviceService.getDeviceID(),
      ProductId,
      LineId: null,
      List,
      Quantity: 1,
      UserImageUrl,
      Location,
      RecognitionId,
      Date: new Date().toISOString(),
    });
  }

  createLogFromLineId(
    LineId: number,
    List: ICigarLogListType,
    {
      UserImageUrl,
      Location,
      RecognitionId,
    }: {
      UserImageUrl?: string;
      Location?: string;
      RecognitionId?: number;
    }
  ) {
    return this.http.post<ICigarLog>('cigarlogs', {
      UUID: this.deviceService.getDeviceID(),
      ProductId: null,
      LineId,
      List,
      Quantity: 1,
      UserImageUrl,
      Location,
      RecognitionId,
      Date: new Date().toISOString(),
    });
  }

  /**
   * copy a log keeps the user uploaded images, that's the reason
   * why we copy and not addCigar
   */
  copy(logId: number, listName: ICigarLogListType) {
    return this.http.post<ICigarLog>(
      `cigarlogs/${logId}/copyto/${listName}?DeleteOriginal=false`,
      {}
    );
  }

  update(item: ICigarLog) {
    const data = _pick(item, [
      'ProductId',
      'LineId',
      'List',
      'Quantity',
      'UserImageUrl',
      'Location',
      'Price',
      'Date',
    ]);

    data['UUID'] = this.deviceService.getDeviceID();

    return this.http.put<ICigarLog>(`cigarlogs/${item.Id}`, data);
  }

  updatePrice(logId: number, Price: number) {
    return this.http.put<ICigarLog>(`cigarlogs/${logId}`, {
      Price,
    });
  }

  updateQuantity(logId: number, Quantity: number) {
    return this.http.put<ICigarLog>(`cigarlogs/${logId}`, {
      Quantity,
    });
  }

  delete(logId: number) {
    return this.http.delete<void>(`cigarlogs/${logId}`);
  }
}

function _getSortBy(fieldName: string) {
  if (fieldName === NAME_SORT_FIELD) {
    return 'name';
  } else if (fieldName === RATING_SORT_FIELD) {
    return 'rating';
  } else if (fieldName === MY_RATING_SORT_FIELD) {
    return 'myrating';
  }

  return 'date';
}
